import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons/faGlobeAmericas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import AggregatedFromSimilarInfoPopUp from "../AggregatedFromSimilarInfoPopUp";
import WolrdGeographies from "../MapsData/worldGeoJson.js";
import DemographicFilterTitle from "./DemographicFilterTitle";
import InsightsDemographicsInfoPopUp from "./InsightsDemographicsInfoPopUp";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  mainContent: {
    flexDirection: "column",
    gap: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
    },
    [ScreenSizes.xlAndAbove]: {
      gap: 0,
    },
  },
  headingWrapper: {
    position: "absolute",
    width: "100%",
  },
  heading: {
    ...gStyles.avalonBold,
    color: "var(--color-neutral-d5)",
    fontSize: "0.8125rem",
    fontStyle: "normal",
    lineHeight: 1,
    margin: 0,
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: "0.5rem",
    position: "relative",
  },
  ul: {
    justifyItems: "center",
    alignContent: "flex-start",
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "0.75rem",
    flex: "1 0 0",
    alignSelf: "stretch",
    marginLeft: "-1rem",
    paddingLeft: "1rem",
    paddingBottom: "1rem",

    [ScreenSizes.mdAndAbove]: {
      marginLeft: 0,
      paddingLeft: 0,
      paddingBottom: 0,
    },
  },
  listStyle: {
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
  },
  title: {
    ...gStyles.fontRegular,
    lineHeight: "0.8125rem",
    letterSpacing: "0em",
    textAlign: "left",
    padding: "0.1875rem 0.5rem 0.0625rem 0.5rem",
    textWrap: "nowrap",
    color: "--color-neutral-d1",
    fontSize: "0.875rem",
  },
  dot: {
    ...gStyles.avalonBold,
    width: "1rem",
    height: "1rem",
    color: colours.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.25rem",
    textAlign: "center",
    fontSize: "0.75rem",
  },
  boldTitle: {
    ...gStyles.fontBold,
  },
  mapOuter: {
    width: "100%",
    maxHeight: "14rem",
    maxWidth: "25.5rem",
  },
  mapWrapper: {
    width: "100%",

    display: "flex",
    maxWidth: "25.5rem",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    gap: "0.5rem",
    paddingTop: "1rem",
    maxHeight: "16.5rem",
  },
  outer: {
    position: "relative",
  },
  seperator: {
    height: 1,
    background: "var(--color-neutral-d9)",
    width: "calc(100% + 2rem)",
    margin: "0 -1rem",
    position: "absolute",
    bottom: 0,
  },

  info: {
    display: "flex",
    alignItems: "center",
    gap: "0.625rem",
  },
  infoWrapper: {
    position: "absolute",
    right: 0,
    top: 0,

    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
  },
};

const iconStyles = {
  infoIcon: {
    alignSelf: "center",
    display: "flex",
    fontSize: "1rem",
  },
};

const ROW_LIMIT = 5;

const COLOR_MAPPING = {
  0: colours.dataVisPrimary,
  1: colours.dataVisSecondary,
  2: colours.dataVisTertiary,
  3: colours.dataVisQuaternary,
  4: colours.dataVisQuinary,
};

const popUpStyles = {
  outer: {
    marginLeft: 0,
    width: "100%",
    [ScreenSizes.mdAndAbove]: {
      marginLeft: -260,
      bottom: 30,
    },
  },
  triangle: {
    right: -10,
    left: "auto",
  },
};

const DEFAULT_COUNTRY_COLOR = "var(--color-neutral-d9)";

export function countryItemValue(value) {
  if (value > 75) {
    return (
      <span>
        <b>Very High:</b> Over 75% of listeners
      </span>
    );
  } else if (value >= 50 && value <= 75) {
    return (
      <span>
        <b>High:</b> 50-75% of listeners
      </span>
    );
  } else if (value >= 25 && value < 50) {
    return (
      <span>
        <b>Moderately High:</b> 25-50% of listeners
      </span>
    );
  } else if (value >= 15 && value < 25) {
    return (
      <span>
        <b>Moderately Low:</b> 15-25% of listeners
      </span>
    );
  } else if (value >= 5 && value < 15) {
    return (
      <span>
        <b>Low:</b> 5-15% of listeners
      </span>
    );
  } else {
    return (
      <span>
        <b>Niche:</b> Less than 5% of listeners
      </span>
    );
  }
}

const InsightsMapChart = (props) => {
  const {
    topCountries,
    aggregatedFromSimilar,
    podcast,
    handlePopUp,
    showPopUp,
    filters,
    filter,
  } = props;
  const { styles, css } = useStyles(baseStyles, props);

  const sortByPercentageDescending = (a, b) => {
    const percentageA = a.get("percentage");
    const percentageB = b.get("percentage");

    if (percentageA > percentageB) {
      return -1;
    }
    if (percentageA < percentageB) {
      return 1;
    }

    return 0;
  };

  const sortedData = topCountries
    ?.sort(sortByPercentageDescending)
    .filter((item) => item.get("percentage") > 0)
    .slice(0, ROW_LIMIT);

  return (
    <div className={css(styles.outer)}>
      <div className={css(styles.headingWrapper)}>
        <h2 className={css(styles.heading)}>
          <FontAwesomeIcon icon={faGlobeAmericas} />
          <span>Countries</span>
          <div className={css(styles.info)}>
            <div className={css(styles.infoWrapper)}>
              {aggregatedFromSimilar && (
                <AggregatedFromSimilarInfoPopUp
                  title={"countries"}
                  podcast={podcast}
                  showPopUp={showPopUp}
                  handlePopUp={handlePopUp}
                  popUpStyles={popUpStyles}
                  styles={iconStyles}
                />
              )}

              <InsightsDemographicsInfoPopUp
                title={"Countries"}
                info={
                  "Our estimate of the top locations where this podcast's audience resides, as determined by predictive models."
                }
                podcast={podcast}
                showPopUp={showPopUp}
                handlePopUp={handlePopUp}
                popUpStyles={popUpStyles}
                helpIconStyles={iconStyles}
              />
            </div>
          </div>
        </h2>
      </div>
      <div className={css(styles.mainContent)}>
        <div className={css(styles.mapOuter)}>
          <div className={css(styles.mapWrapper)}>
            <ComposableMap
              projection="geoMercator"
              onMouseDown={(e) => e.preventDefault()}
              data-testid="map"
              width={1000}
              height={875}
              style={{ width: "100%", height: "100%" }}
            >
              <Geographies geography={WolrdGeographies}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const country = sortedData.find(
                      (c) => c.get("title") === geo.properties.name
                    );

                    const position = sortedData.findIndex(
                      (c) => c.get("title") === geo.properties.name
                    );

                    const fillColor = country
                      ? COLOR_MAPPING[position]
                      : DEFAULT_COUNTRY_COLOR;

                    return (
                      <Geography
                        key={geo.properties.name}
                        geography={geo}
                        fill={fillColor}
                        data-tip={`${geo.properties.name} ${geo}`}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
          </div>
        </div>

        <ul className={css(styles.ul)}>
          {sortedData.map((country, index) => (
            <li className={css(styles.listStyle)} key={index}>
              <span
                className={css(styles.dot)}
                style={{ background: COLOR_MAPPING[index] }}
              >
                {index + 1}
              </span>
              <span
                className={css(
                  styles.title,
                  (index === 0 || index === 1) && styles.boldTitle
                )}
              >
                <BasicTooltip
                  renderTooltip={() =>
                    countryItemValue(
                      Math.round(country.get("percentage") * 10000) / 100
                    )
                  }
                >
                  {(tooltipProps) => (
                    <span {...tooltipProps}>
                      <DemographicFilterTitle
                        data-testid={country.get("title")}
                        title={country.get("title")}
                        filter={filter}
                        filters={filters}
                        podcast={podcast}
                        filterValue={country
                          ?.get("title")
                          ?.toLowerCase()
                          .replace(/\s/g, "")}
                      />
                    </span>
                  )}
                </BasicTooltip>
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className={css(styles.seperator)} />
    </div>
  );
};

export default InsightsMapChart;

InsightsMapChart.propTypes = {
  topCountries: PropTypes.instanceOf(List),
  podcast: PropTypes.instanceOf(Map),
  showPopUp: PropTypes.object,
  handlePopUp: PropTypes.func,
};
